import { all, call, put, takeLatest } from "redux-saga/effects";
import ActionTypes from "../constants/ActionTypes";
import APICaller from "utils/APICaller";
import config from "config.json";
const { GITUSER } = config;

export function* getRepos() {
  const filters = {
      page: 1,
      per_page: 100,
      direction: "desc",
      sort: "updated",
    },
    url = `users/${GITUSER}/repos`;

  try {
    const response = yield call(APICaller, {
      method: "GET",
      reqUrl: url,
      data: filters,
    });
    const data = response.data.filter((repo) => repo.description && repo.homepage);
    yield put({
      type: ActionTypes.GET_REPOS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: ActionTypes.GET_REPOS_FAILURE,
      payload: [],
    });
  }
}

export default function* root() {
  yield all([takeLatest(ActionTypes.GET_REPOS, getRepos)]);
}
