import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";

import Mail from "assets/svg/Mail";
import Phone from "assets/svg/Phone";
import Github from "assets/svg/Github";
import LinkedIn from "assets/svg/LinkedIn";
import Gitlab from "assets/svg/Gitlab";
import HackerEarth from "assets/svg/HackerEarth";

import config from "config.json";
const { DISPLAYNAME, EMAIL, PHONE, LINKEDIN, GITHUB, GITLAB, HACKEREARTH, PROFILEPIC } = config;

const Home = (props) => {
  return (
    <div className="Home">
      <Container fluid="lg">
        <Row className="Home_row">
          <Col lg={7} className="Home_banner">
            <Badge color="info" pill className="hello">
              <h4 className="marginPadding">Hello I'm</h4>
            </Badge>

            <div className="name">{DISPLAYNAME}</div>
            <div className="designation">Web Devloper</div>

            <div className="Home_banner_contact">
              <Mail className="Home_banner_icon" />
              <a href={`mailto:${EMAIL}`}>{EMAIL}</a>
            </div>

            <div className="Home_banner_contact">
              <Phone className="Home_banner_icon" />
              <a href={`callto:${PHONE}`}>{PHONE}</a>
            </div>

            <div className="Home_banner_social">
              {[
                {
                  componet: LinkedIn,
                  url: LINKEDIN,
                },
                {
                  componet: Github,
                  url: GITHUB,
                },
                {
                  componet: Gitlab,
                  url: GITLAB,
                },
                {
                  componet: HackerEarth,
                  url: HACKEREARTH,
                },
              ].map((row, index) => {
                const Icon = row.componet;
                return (
                  <a key={index} href={row.url} target="_blank" rel="noopener noreferrer">
                    <Icon className="Home_banner_icon" />
                  </a>
                );
              })}
            </div>
          </Col>
          <Col lg={5}>
            <div className="img-border">
              <img src={PROFILEPIC} alt="" className="img-fluid" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Home;
