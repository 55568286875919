import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import Layout from './layouts/Layout';
import Home from './Home';
import Portfolio from './Portfolio';
import Skills from './Skills';

const Root = (props) => {
  return (
    <Layout>
      <Switch>
        <Route path='/home' exact component={Home} />
        <Route path='/portfolio' exact component={Portfolio} />
        <Route path='/skills' exact component={Skills} />

        <Redirect to='/home' />
      </Switch>
    </Layout>
  );
};
export default Root;
