import React from "react";

export default function Gitlab(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      height="30px"
      width="30px"
      className={props.className}
    >
      <path fill="#e53935" d="M24 43L16 20 32 20z" />
      <path fill="#ff7043" d="M24 43L42 20 32 20z" />
      <path fill="#e53935" d="M37 5L42 20 32 20z" />
      <path fill="#ffa726" d="M24 43L42 20 45 28z" />
      <path fill="#ff7043" d="M24 43L6 20 16 20z" />
      <path fill="#e53935" d="M11 5L6 20 16 20z" />
      <path fill="#ffa726" d="M24 43L6 20 3 28z" />
    </svg>
  );
}
