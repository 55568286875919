import { combineReducers } from "redux";

import loadingReducer from "./loading";
import reposReducer from "./repos";

const rootReducer = combineReducers({
  loadingState: loadingReducer,
  reposState: reposReducer
});

export default rootReducer;
