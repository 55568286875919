import React from "react";

import SkillsCard from "components/SkillsCard";
import config from "config.json";
const { SKILLS } = config;

function Skills() {
  let skills = [...SKILLS],
    topSkills = skills.splice(0, 8);
  skills = [
    ...topSkills.sort(() => Math.random() - 0.5),
    ...skills.sort(() => Math.random() - 0.5),
  ];
  return (
    <div className="Skills">
      {skills.map((skill, i) => {
        return <SkillsCard skill={skill} key={i} />;
      })}
    </div>
  );
}
export default Skills;
