import React from "react";

export default function HackerEarth(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      aria-hidden="true"
      focusable="false"
      style={{
        msTransform: "rotate(360deg)",
        WebkitTransform: "rotate(360deg)",
        transform: "rotate(360deg)"
      }}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 24 24"
      height="25px"
      width="25px"
      className={props.className}
    >
      <path
        d="M20.983.002H9.508v6.494h.11a3.629 3.629 0 0 1 3.557-1.332l-.023-.004c1.966.144 3.91 1.336 4.02 5.158v7.554a.299.299 0 0 1-.287.287h-2.11a.287.287 0 0 1-.287-.287V10.55c0-1.778-.585-3.114-2.341-3.114h-.038a2.606 2.606 0 0 0-2.6 2.78v-.008v7.665a.287.287 0 0 1-.287.287H7.124a.287.287 0 0 1-.287-.287V.004H3.193L3.126.003c-.823 0-1.493.652-1.523 1.468v20.987c.03.857.73 1.541 1.59 1.546h17.792a1.458 1.458 0 0 0 1.413-1.55v.004V1.474A1.38 1.38 0 0 0 21.021.005l-.038.001h.002zm-2.54 20.983H5.555V19.66h12.888z"
        fill="#342C56"
      />
    </svg>
  );
}
