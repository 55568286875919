import ActionTypes from "../constants/ActionTypes";

export const getRepos = () => {
  return {
    type: ActionTypes.GET_REPOS
  };
};

export const clearRepos = () => {
  return {
    type: ActionTypes.CLEAR_REPOS_LIST
  };
};
