import React, { useEffect } from "react";
import Masonry from "react-masonry-css";
import { connect } from "react-redux";

import PortfolioCard from "components/PortfolioCard";
import { getRepos } from "redux/actions/getRepos";

const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  800: 2,
  500: 1,
};

const ONE_HOUR = 60 * 60 * 1000;

function Portfolio(props) {
  const { repos, lastUpdate, dispatch, reposApiInProgress } = props;
  useEffect(() => {
    if (!repos.length || new Date() - lastUpdate > ONE_HOUR) {
      dispatch(getRepos());
    }
    return () => {};
    // eslint-disable-next-line
  }, []);
  return (
    <div className="Portfolio">
      {reposApiInProgress ? (
        <div>Loading...</div>
      ) : (
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {repos.map((repo, i) => {
            return <PortfolioCard repo={repo} key={i} />;
          })}
        </Masonry>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    repos: state.reposState.repos,
    reposApiInProgress: state.reposState.reposApiInProgress,
    lastUpdate: state.reposState.lastUpdate,
  };
};
export default connect(mapStateToProps)(Portfolio);
