import logo from "assets/img/logo.png";
import userLogo from "assets/img/user-logo.png";
import defaultBg from "assets/img/home-bg-img.jpg";

import batchIcon from "assets/img/icons/batch-icon.png";
import cssIcon from "assets/img/icons/css-icon.png";
import defaultIcon from "assets/img/icons/default-icon.png";
import htmlIcon from "assets/img/icons/html-icon.png";
import jsIcon from "assets/img/icons/js-logo.png";
import pythonIcon from "assets/img/icons/python-icon.png";
import reactIcon from "assets/img/icons/reactjs-logo.png";
import javaIcon from "assets/img/icons/java.png";

import bootstrap_icon from "assets/img/skills/bootstrap.png";
import git_icon from "assets/img/skills/git.png";
import react_native_icon from "assets/img/skills/react-native-icons.png";
import scss_icon from "assets/img/skills/scss.png";
import css_icon from "assets/img/skills/css.png";
import html_icon from "assets/img/skills/html.png";
import storybookjs_icon from "assets/img/skills/storybookjs.png";
import D3js_icon from "assets/img/skills/D3js.png";
import js_logo_icon from "assets/img/skills/js-logo.png";
import redux_icon from "assets/img/skills/redux.png";
import github_icon from "assets/img/skills/github.png";
import mongodb_icon from "assets/img/skills/mongodb.png";
import redux_saga_icon from "assets/img/skills/redux-saga.png";
import visual_Studio_icon from "assets/img/skills/Visual_Studio.png";
import linux_icon from "assets/img/skills/linux.png";
import mac_os_icon from "assets/img/skills/mac_os.png";
import zeplin_icon from "assets/img/skills/zeplin.png";
import socket_icon from "assets/img/skills/logo-socket.png";
import react_bootstrap_icon from "assets/img/skills/react-bootstrap.png";
import redux_form_icon from "assets/img/skills/redux-form.png";
import typescript from "assets/img/skills/typescript.png";
import mobx from "assets/img/skills/mobx.png";
import branch from "assets/img/skills/branch.png";
import cypress from "assets/img/skills/cypress.png";
import mui from "assets/img/skills/mui.png";

const images = {
  logo,
  defaultBg,
  homeRight: userLogo,
  batchIcon,
  cssIcon,
  defaultIcon,
  htmlIcon,
  jsIcon,
  pythonIcon,
  reactIcon,
  javaIcon,
  bootstrap_icon,
  git_icon,
  react_native_icon,
  scss_icon,
  css_icon,
  html_icon,
  storybookjs_icon,
  D3js_icon,
  js_logo_icon,
  redux_icon,
  github_icon,
  mongodb_icon,
  redux_saga_icon,
  visual_Studio_icon,
  linux_icon,
  mac_os_icon,
  zeplin_icon,
  react_bootstrap_icon,
  socket_icon,
  redux_form_icon,
  typescript,
  mobx,
  branch,
  cypress,
  mui,
};
function getImage(imageName) {
  return images[imageName];
}
export default getImage;
