import ActionTypes from "../constants/ActionTypes";

const initialState = {
  repos: [],
  reposApiInProgress: false,
  lastUpdate: new Date()
};

const reposReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_REPOS:
      return Object.assign({}, state, {
        reposApiInProgress: true
      });
    case ActionTypes.GET_REPOS_FAILURE:
      return Object.assign({}, state, {
        reposApiInProgress: false
      });
    case ActionTypes.GET_REPOS_SUCCESS:
      return Object.assign({}, state, {
        repos: [...action.payload],
        lastUpdate: new Date(),
        reposApiInProgress: false
      });
    case ActionTypes.CLEAR_REPOS_LIST:
      return Object.assign({}, state, {
        repos: []
      });
    default:
      return state;
  }
};

export default reposReducer;
