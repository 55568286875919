import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { withRouter } from "react-router-dom";
import get from "lodash/get";
// import getImage from "utils/images";

// @ts-ignore
// import colors from "assets/css/colors.scss";

// const { theme } = colors;

const Header = (props) => {
  const pathName = get(props, "history.location.pathname", "");
  return (
    <div className="Header center">
      <Navbar variant="dark" expand="md" className="Header_navbar">
        <Navbar.Brand href="/home">
          Manish
          {/* <img
            src={getImage("logo")}
            alt="logo"
            style={{ maxHeight: 35, opacity: 0.8 }}
          /> */}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Item>
              <Nav.Link active={pathName === "/home"} href="/home" className="Header_navLink">
                Home
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                active={pathName === "/portfolio"}
                href="/portfolio"
                className="Header_navLink"
              >
                Portfolio
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link active={pathName === "/skills"} href="/skills" className="Header_navLink">
                Skills
              </Nav.Link>
            </Nav.Item>
          </Nav>
          {/* <NavbarText>Simple Text</NavbarText> */}
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

const HeaderWithRouter = withRouter((props) => <Header {...props} />);
export default HeaderWithRouter;
